/* eslint-disable react/react-in-jsx-scope -- Unaware of jsxImportSource */
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { BG_URL, PUBLIC_URL } from '../../Utils/utils';
import { Box, IconButton, Typography } from '@mui/material';
import PinDropIcon from '@mui/icons-material/PinDrop';
import EmailIcon from '@mui/icons-material/Email';
import TwitterIcon from '@mui/icons-material/Twitter';
import YouTubeIcon from '@mui/icons-material/YouTube';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
export default function Footer() {
    const navigate = useNavigate()
    return (
        <section style={{
            scrollSnapAlign: "center",
            backgroundImage: BG_URL(PUBLIC_URL("/assets/Footer-back.png")),
            backgroundSize: "cover",
            backgroundPosition: "center",
            minHeight: "100vh",
            maxHeight: "100vh",
        }} css={css`
            color: white !important;
        `}
            className='text-center'
        >
            {/* shadow box */}
            <div sx={{ width: "100%", boxShadow: "0px 0px 15px 10px rgba(0,0,0,0.6)" }} />
            <div className='container'>
                <div css={css`padding:40px 0px 0px 0px;padding-top: 35%;
                @media (max-width: 600px) {padding-top:90%;}`}>
                    <div className='row'>
                        <div css={css`min-height:200px;`} className='col-6 col-md-3'>
                            <Typography mb={2} css={css`font-size:30px;font-weight:700;
                            @media (max-width:600px) {font-size:20px;}`}>DELZAAR STUDIO</Typography>
                            <Typography fontSize={20} css={css`font-size:20px;
                            @media (max-width:600px) {font-size:16px;}`}>Outsource Game Studio</Typography>
                        </div>
                        <div css={css``} className='col-6 col-md-3'>
                            <Typography mb={2} css={css`font-size:30px;font-weight:700;
                            @media (max-width:600px) {font-size:20px;}`}>ABOUT</Typography>
                            <FooterLink id="projects" text={"Projects"} />
                            <FooterLink id="aboutDelzaar" text={"About Us"} />
                            <FooterLink id="Team" text={"Our Team"} />
                            {/* <FooterLink id="" text={"Store"} /> */}
                        </div>
                        <div css={css``} className='col-6 col-md-3'>
                            <Typography mb={2} css={css`font-size:30px;font-weight:700;
                            @media (max-width:600px) {font-size:20px;}`}>ADDRESS</Typography>
                            <Box css={css`display:flex;justify-content:center;align-items:center;`}>
                                <PinDropIcon />
                                <Typography css={css`font-size:18px;
                                @media (max-width:600px) {font-size:15px;}`}>South Africa-Johannesburg</Typography>
                            </Box>
                            <Box my={2} css={css`display:flex;justify-content:center;align-items:center;`}>
                                <EmailIcon />
                                <Typography css={css`font-size:18px;
                                @media (max-width:600px) {font-size:15px;}`}>Delzaarco@gmail.com</Typography>
                            </Box>
                        </div>
                        <div css={css``} className='col-6 col-md-3'>
                            <Typography mb={2} css={css`font-size:30px;font-weight:700;
                            @media (max-width:600px) {font-size:20px;}`}>FOLLOW US</Typography>
                            <div css={css`display:flex;align-items:center;justify-content:center;margin-top:10px;gap:5px;flex-direction:row;`}>
                                <Link target='_blank' to="https://twitter.com/Delzaar_Studio">
                                    <TwitterIcon sx={{ fill: 'white', fontSize: '30px' }} />
                                </Link>
                                <Link target='_blank' to="https://www.youtube.com/channel/UCdytGT8l_H2ACU3MzvMScXA">
                                    <YouTubeIcon sx={{ fill: 'white', fontSize: '30px' }} />
                                </Link>
                                <Link target='_blank' to="https://www.instagram.com/delzaarco/">
                                    <InstagramIcon sx={{ fill: 'white', fontSize: '30px' }} />
                                </Link>
                                <Link target='_blank' to="https://www.facebook.com/profile.php?id=100092810316754">
                                    <FacebookIcon sx={{ fill: 'white', fontSize: '30px' }} />
                                </Link>
                                <Link target='_blank' to="https://www.linkedin.com/company/13062913/">
                                    <LinkedInIcon sx={{ fill: 'white', fontSize: '30px' }} />
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Typography sx={{ fontSize: { xs: 12, md: 16 } }} py={"40px"}>©2025 Delzaar co. All rights reserved. Trademarks belong to their respective owners.</Typography>
        </section>
    )
}
const FooterLink = ({ text, id }) => {
    const location = useLocation()
    const navigate = useNavigate()
    return <Box sx={{ cursor: 'pointer' }} onClick={() => {
        // navigate(`/#${id}`);
        let element = window.document.getElementById(id);
        if (element) {
            element.scrollIntoView();
            return;
        }
        navigate('/#');
        setTimeout(() => {
            let element = window.document.getElementById(id);
            console.log(id);
            console.log(element);
            if (element) {
                element.scrollIntoView({
                    behavior: "smooth", block: "end", inline: "nearest"
                });
                return;
            }
        }, 0);
        // return;
    }}>
        <Typography textAlign={"center"} component={"div"} >{text}</Typography>
    </Box>
}